import Dwc from './Dwc';

class DwcServices extends Dwc {
    /**
     * @deprecated
     * @type {[]}
     */
    filteredElementsDwc = [];

    constructor(name) {
        super(name);

        this.template = document.querySelector('#services-item').content;
        this.container = document.querySelector('#servicesDwc');
        this.modalOverlay = document.querySelector('.dwc-modal-overlay');
        this.modal = document.querySelector('.dwc-modal');
        this.closeButton = document.querySelector('.dwc-modal__header-close');
        this.noNeedButton = document.querySelector('.dwc-modal__button-cancel');
        this.renderButton = document.querySelector('.dwc-modal__button-accept');

        this.bind();
    }

    /** @param {[]} services */
    renderInitial(services) {
        if (services && services.length > 0) {
            this.render(services);
        }

        this.show();
    }

    getDefaultContent() {
        // он уже вставлен по умолчанию бекендом
        return false;
    }

    /** @param {[]} services */
    renderFromOsumk(services) {
        this.showModalPromise().then(() => {
            this.render(services);
        });
    }

    render(services) {
        const $container = $(this.container);
        $container.empty();
        services.map(item => {
            $container.append(this.renderElements(item));
        });
    }

    /**
     * Метод расширен (относительно родителя) чтобы
     * - очистить данные
     * - дозапросить справочник
     * - найти рекомендованные услуги по uuid в справочнике
     * @returns {Promise<[]|boolean>}
     */
    async getContent(saveCache) {
        try {
            const content = await super.getContent(false);

            if (!content) {
                return false;
            }

            const dwcServices = this.cleanAndParse(content);

            const uuidServiceList = dwcServices.map(({ uuid }) => uuid);

            if (uuidServiceList.length === 0) {
                console.warn('[dwc][services] не переданы uuid');
                return false;
            }

            const dictionaryServices = await this.getServicesDictionaryFromBitrix();

            let foundServices = dictionaryServices.data.filter(({ uuid }) =>
                uuidServiceList.includes(uuid)
            );

            if (foundServices.length > 0) {
                // cохраняем кеш
                this.cache.store(foundServices);

                return foundServices;
            }

            console.warn('[dwc][services] не найдены рекомендованные услуги в справочнике');
            return false;
        } catch (e) {
            console.log('[dwc][services] ошибка запроса', e);
            return [];
        }
    }

    cleanAndParse(content) {
        const replaced = content.replace(/<\/?p>|<br\s?\/?>|&nbsp;/gi, '');
        return JSON.parse(replaced);
    }

    bind() {
        this.closeButton.addEventListener('click', () => this.hideModal());
        this.noNeedButton.addEventListener('click', () => this.hideModal());
    }

    showModalPromise() {
        this.showModal();

        return new Promise(resolve => {
            const handler = () => {
                this.renderButton.removeEventListener('click', handler);
                this.hideModal();
                resolve();
            };

            this.renderButton.addEventListener('click', handler);
        });
    }

    showModal() {
        this.modalOverlay.classList.add('active');
        this.modal.classList.add('active');
        document.querySelector('#root-container').classList.add('hidden');
        document.querySelector('html').style.overflow = 'hidden';
    }

    hideModal() {
        this.modalOverlay.classList.remove('active');
        this.modal.classList.remove('active');
        document.querySelector('#root-container').classList.remove('hidden');
        document.querySelector('html').style.overflow = 'none';
    }

    /**
     * Получение справочника услуг из Bitrix
     * @returns {Promise<any>}
     */
    async getServicesDictionaryFromBitrix() {
        const servicesBitrix = await fetch('/internal/services/getAll/');
        return await servicesBitrix.json();
    }

    show() {
        $('.services-item').removeClass('hide');
    }

    renderElements(element) {
        const { detailPageUrl, name, description, tags } = element;
        const card = this.template.querySelector('.services-item').cloneNode(true);
        card.querySelector('.services-item__title a').href = detailPageUrl;
        card.querySelector('.services-item__title a').innerText = name;
        card.querySelector('.services-item__desc').innerText = description;
        if (tags.length > 0) {
            card.querySelector('.services-item__icons-wrap').innerHTML =
                '<div class="services-item__icons"></div>';
            tags.map(({ name, src }) => {
                card.querySelector('.services-item__icons').innerHTML += `
                        <div class="services-item__icons-item">
                            <img
                                src="${src}"
                                alt="${name}"
                                class="services-item__icons-img"
                            />
                            <span class="services-item__icons-tooltip">${name}</span>
                        </div>
                `;
            });
        }
        card.querySelector('.button-base').href = element.url;
        card.querySelector('.button-base').setAttribute('data-uuid', element.uuid);
        card.querySelector('.services-item__btn-more').href = element.detailPageUrl;
        if (element.org) {
            card.querySelector('.services-item__icons-wrap').innerHTML += `
                <a
                    href="${element.orgUrl}"
                    target="_blank"
                    class="services-item__logo services-item__logo--link"
                >
                    <div class="services-item__logo-img">
                        <img
                            src="${element.icon}"
                            alt="${element.org}"
                        />
                    </div>
                        <div class="services-item__logo-name">${element.org}</div>
                </a>
            `;
        }
        return card;
    }
}

export default DwcServices;
