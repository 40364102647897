import Dwc from './Dwc';

class dwcEKU extends Dwc {
    constructor(name, nameStore = false) {
        super(name, nameStore);

        this.container = document.querySelector('#recommended_items');
        this.modalOverlay = document.querySelector('.dwc-modal-overlay');
        this.modal = document.querySelector('.dwc-modal');
        this.renderButton = document.querySelector('.dwc-modal__button-accept');
        this.header = document.querySelector('#root-container');
    }

    async uuidList() {
        const cacheContent = this.getCacheContent();
        const osumkContent = await this.getContent();
        const osumkContentExist = osumkContent !== false;

        if (osumkContentExist && !this.isContentEquivalent(osumkContent, cacheContent)) {
            this.showModal();
            return true;
        } else {
            return false;
        }
    }

    showModal() {
        this.modalOverlay.classList.add('active');
        this.modal.classList.add('active');
        this.header.classList.add('hidden');
        document.querySelector('html').style.overflow = 'hidden';
    }


    hideModal() {
        this.modalOverlay.classList.remove('active');
        this.modal.classList.remove('active');
        this.header.classList.remove('hidden');
        document.querySelector('html').style.overflow = 'auto';
    }

    /**
     * Метод расширен (относительно родителя) чтобы
     * - очистить данные
     * - дозапросить справочник
     * - найти рекомендованные услуги по uuid в справочнике
     * @returns {Promise<[]|boolean>}
     */
    async getContent() {
        try {
            const content = await super.getContent(false);

            if (!content) {
                return false;
            }

            const dwcServices = this.cleanAndParse(content);
            const uuidServiceList = dwcServices.map(({ uuid }) => uuid);

            if (uuidServiceList.length > 0) {
                // cохраняем кеш
                this.cache.store(uuidServiceList);

                return uuidServiceList;
            }

            if (uuidServiceList.length === 0) {
                console.warn('[dwc][services] не переданы uuid');
                return false;
            } else {
                return uuidServiceList;
            }

        } catch (e) {
            console.log('[dwc][eku] ошибка ответа', e);
            return [];
        }
    }

    cleanAndParse(content) {
        const replaced = content.replace(/<\/?p>|<br\s?\/?>|&nbsp;/gi, '');
        return JSON.parse(replaced);
    }
}

export default dwcEKU;
