class LSCache {
    /**
     * default: неделя
     */
    expire = 7 * 24 * 60 * 60;

    /** @type {string} */
    key;

    /**
     *
     * @param {string} key
     * @param {number|null} expire
     */
    constructor(key, expire = null) {
        if (expire) {
            this.expire = expire;
        }

        this.key = this.generateCacheKey(key);
    }

    getData() {
        if (this.isExpired()) {
            return false;
        }

        return JSON.parse(localStorage.getItem(this.key)) || false;
    }

    /** @param {object} content */
    store(content) {
        localStorage.setItem(this.key, JSON.stringify(content));
        localStorage.setItem(this.key + ':ts', Date.now().toString());
    }

    generateCacheKey(id) {
        return `content_${id}`;
    }

    clear() {
        localStorage.removeItem(this.key);
        localStorage.removeItem(this.key + ':ts');
    }

    isExpired() {
        let whenCached = localStorage.getItem(this.key + ':ts');
        if (!whenCached) {
            console.debug(`[Cache] ${this.key} is not found`);
            return false;
        }

        let age = (Date.now() - whenCached) / 1000;
        // console.debug(`[Cache] ${this.key} is Expired`, age > this.expire);

        if (age > this.expire) {
            this.clear();
            return true;
        } else {
            return false;
        }
    }
}

export default LSCache;
