import LSCache from './LSCache';

/**
 * Абстрактный класс для интеграции динамического контента (dwc)
 * из подсистемы ОСУМК
 *
 * Необходимо имплементировать методы
 * renderInitial
 * renderFromOsumk
 * getDefaultContent
 */
class Dwc {
    /**
     * Название слота dwc
     * @type {string}
     */
    slotName;

    constructor(name, nameStore) {
        this.slotName = name;

        this.cache = new LSCache(nameStore || name);

        // @todo need?
        this.content = this.getCacheContent();
    }

    /**
     * Основная логика показа контента
     * 1. Если есть кеш берем его
     * 2. Если кеша нет - показываем дефолт
     * 3. Делаем запрос в осумк. Если есть данные - перекрываем.
     *
     * Проверка на false не случайна, т.к. !![] == true
     * @returns {Promise<void>}
     */
    async init() {
        const cacheContent = this.getCacheContent();
        if (cacheContent !== false) {
            console.log(`${this.getLogKey()} render from cache`);
            this.renderInitial(cacheContent);
        } else {
            console.log(`${this.getLogKey()} render default content`);
            this.renderInitial(this.getDefaultContent());
        }

        const osumkContent = await this.getContent();
        const osumkContentExist = osumkContent !== false;

        if (osumkContentExist && !this.isContentEquivalent(osumkContent, cacheContent)) {
            console.log(`${this.getLogKey()} render from osumk`);
            this.renderFromOsumk(osumkContent);
        }
    }

    /**
     * Запускается при старте страницы
     * @param {string} content
     */
    renderInitial(content) {}

    /**
     * Запускается при успешном ответе от ОСУМК
     * @param {string} content
     */
    renderFromOsumk(content) {}

    /**
     * Получение контента по умолчанию из портала
     */
    getDefaultContent() {}

    /** @returns {Promise<[]|string|boolean>} */
    async getContent(saveCache = true) {
        let httpResponse, response;
        try {
            httpResponse = await this.requestToOsumk();

            if (!httpResponse?.ok) {
                console.error(
                    `${this.getLogKey()} ошибка ответа от ОСУМК`,
                    httpResponse.status,
                    httpResponse.statusText
                );
                return false;
            }

            if (httpResponse.status === 204) {
                return false;
            }

            response = await httpResponse.json();
        } catch (requestError) {
            console.error(`${this.getLogKey()} Ошибка запроса`, requestError);
            return false;
        }

        // послан ответ сброса
        if (this.isResetResponse(response.content)) {
            console.log(`${this.getLogKey()} Послан ответ сброса`);
            this.cache.clear();
            return false;
        }

        // сохраняем кеш
        if (saveCache) {
            this.cache.store(response.content);
        }

        return response.content;
    }

    getCacheContent() {
        if (this.cache.isExpired()) {
            return false;
        }

        return this.cache.getData();
    }

    /**
     * ОСУМК может послать 'false' для сброса контента в default
     * @param {string} content
     * @returns {boolean}
     */
    isResetResponse(content) {
        return content.toLowerCase().indexOf('false') > 0;
    }

    requestToOsumk() {
        const url = `${this.getHost()}/mautic/dwc/${this.slotName}`;
        const queryString = `mautic_device_id=${this.getDeviceId()}&yandex_id=${this.getYandexId()}`;
        return fetch(`${url}?${queryString}`, {
            headers: {
                'x-requested-with': 'XMLHttpRequest',
            },
            credentials: 'include', // @todo могут быть проблемы
        });
    }

    getHost() {
        switch (document.location.host) {
            case 'master-portal-dev.d.exportcenter.ru':
                return 'http://api-gw.uidm-dev.d.exportcenter.ru';
            case 'master-portal.t.exportcenter.ru':
                return 'https://api-gw.t.exportcenter.ru';
            case 'master-portal.d.exportcenter.ru':
                return 'https://api-gw.d.exportcenter.ru';
            case 'myexport.exportcenter.ru':
                return 'https://api-gw.exportcenter.ru';
            default:
                return 'https://api-gw.t.exportcenter.ru';
        }
    }

    /** @returns {string|null} */
    getYandexId() {
        const yandexId = localStorage.getItem('_ym_uid');

        if (!yandexId) {
            console.warn('[dwc] yandexId не определен');
        }

        return yandexId;
    }

    getDeviceId() {
        const MauticDeviceId = localStorage.getItem('mautic_device_id');

        if (!MauticDeviceId) {
            console.warn('[dwc] MauticDeviceId не определен');
        }

        return MauticDeviceId;
    }

    getLogKey() {
        return `[dwc][${this.slotName}]`;
    }

    /**
     * Проверка эквивалентности контента
     * JSON используется для проверки если
     * типы не только строковые (например массив)
     * @param osumkContent
     * @param cacheContent
     * @returns {boolean}
     */
    isContentEquivalent(osumkContent, cacheContent) {
        return JSON.stringify(osumkContent) === JSON.stringify(cacheContent);
    }
}

export default Dwc;
