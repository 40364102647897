import Dwc from './Dwc';

class DwcSlider extends Dwc {
    /**
     * @type {HTMLDivElement}
     */
    slide;

    /**
     * Заполняется
     */
    insertedSliderIndex;

    constructor(name) {
        super(name);
        this.slide = document.querySelector('#main-banner-slider');
    }

    getDefaultContent() {
        // не требуется
        return false;
    }

    renderInitial(content) {
        if (content) {
            // может прийти false от default т.к. не требуется
            this.changeSlide(content);
        }
    }

    renderFromOsumk(content) {
        this.changeSlide(content);
    }

    changeSlide(content) {
        if (!this.slide && !this.insertedSliderIndex) {
            return false;
        }

        const slideIndex = this.getReplacedSlideIndex();

        if (!slideIndex) {
            return false;
        }

        const swiper = document.querySelector('.swiper').swiper;

        swiper.removeSlide(slideIndex);
        swiper.addSlide(Number(slideIndex), content);

        if (Number(slideIndex) === 0) {
            swiper.slideTo(slideIndex + 1);
        }

        swiper.autoplay.start();
    }

    getReplacedSlideIndex() {
        if (!this.insertedSliderIndex) {
            this.insertedSliderIndex = this.slide.getAttribute('data-swiper-slide-index');
        }

        return this.insertedSliderIndex;
    }
}

export default DwcSlider;
