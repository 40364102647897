import Dwc from './Dwc';

class DwcBanner extends Dwc {
    container;

    constructor(name) {
        super(name);

        this.container = document.querySelector(`#${this.slotName}`);
    }

    getDefaultContent() {
        // он уже вставлен по умолчанию бекендом
        return false;
    }

    renderInitial(content) {
        if (content) {
            this.container.innerHTML = content;
        }

        this.show();
    }

    renderFromOsumk(content) {
        this.hide();
        this.container.innerHTML = content;
        this.show();
    }

    show() {
        this.container.classList.remove('fade-out');
        this.container.classList.remove('visibility-hide');
        this.container.style.visibility = 'visible';
    }

    hide() {
        this.container.classList.add('fade-out');
    }
}

export default DwcBanner;
